import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../StateProvider';
import Popup from '../../../../../Utilities/components/Popup';
import { fetchFamilyMember, getMembershipById, updateMembership, deleteMembership } from '../../../../../Utilities/api';
import { BackButton } from '../../../../../Utilities/components/Buttons';
import './MembershipEdit.css';

import personPlaceholder from '../../../../../../assets/Dashboard/Profile/personPlaceholder.png';
import dogPlaceholder from '../../../../../../assets/Dashboard/Profile/dogPlaceholder.png';
import catPlaceholder from '../../../../../../assets/Dashboard/Profile/catPlaceholder.png';

const membershipPrices = {
    Basic: 600,
    Premium: 1100,
    VIP: 2000
};

const groomingPrices = {
    Basic: 150,
    Premium: 450,
    VIP: 1500
};

const membershipBenefits = {
    Basic: [
        "15 days of Daycare",
        "High Quality & Nutritious Meals",
        "Access to Exclusive Events",
        "10% Discounts on any Service & Products",
        "Regular Hygiene and Health check-ups"
    ],
    Premium: [
        "30 days of Daycare",
        "High Quality & Nutritious Meals",
        "Access to Exclusive Events",
        "1 Adventure of your choice per month",
        "15% Discounts on any Service & Products",
        "Regular Hygiene and Health check-ups"
    ],
    VIP: [
        "30 days of Daycare",
        "Hand-made Premium Meals",
        "Shuttle Service for Daycare",
        "Access to Exclusive Events",
        "1 Adventure of your choice per month",
        "20% Discounts on any Service & Products",
        "Regular Hygiene and Health check-ups"
    ]
};

const groomingBenefits = {
    Basic: [
        "Daily Teeth Brushing",
        "1 Full Grooming",
        "1 Full Bath (Flea & Tick Treatment)"
    ],
    Premium: [
        "Daily Teeth Brushing",
        "Daily Deshedding/Brushing",
        "Basic Paw Care",
        "2 Full Groomings",
        "2 Full Baths (Flea & Tick Treatment)"
    ],
    VIP: [
        "Deep Teeth Cleaning every 3 months",
        "Daily Teeth Brushing",
        "Daily Deshedding/Brushing",
        "Advanced Paw Care",
        "Unlimited Full Groomings",
        "Unlimited Full Baths (Flea & Tick Treatment)"
    ]
};

const MembershipEdit = () => {
    const { membershipId } = useParams();
    const navigate = useNavigate();
    const { user, paymentMethods, refreshUserData } = useContext(StateContext);
    const [member, setMember] = useState({});
    const [defaultMethod, setDefaultMethod] = useState(null);
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [initialMembership, setInitialMembership] = useState({});
    const [membership, setMembership] = useState({});
    const [loading, setLoading] = useState(true);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [paymentDifference, setPaymentDifference] = useState(0);
    const [isUpgrade, setIsUpgrade] = useState(false);

    // Effect to set initial contact details
    useEffect(() => {
        if (paymentMethods && user) {
            const defaultPaymentMethod = paymentMethods.find(method => String(method.id) === user.defaultPaymentMethodId);
            setDefaultMethod(defaultPaymentMethod || paymentMethods[0]);
            setStripeCustomerId(user.stripeCustomerId);
        }
    }, [user, paymentMethods]);

    useEffect(() => {
        const fetchMembership = async () => {
            try {
                const memberData = await fetchFamilyMember(membershipId);
                const membershipData = await getMembershipById(membershipId);
                setMember(memberData);
                setMembership(membershipData);
                setInitialMembership(membershipData);
                calculatePriceDifference(membershipData.membershipType, membershipData.groomingMembershipType);
            } catch (error) {
                console.error('Failed to fetch membership:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMembership();
    }, [membershipId]);

    useEffect(() => {
        if (initialMembership.membershipType && initialMembership.groomingMembershipType) {
            calculatePriceDifference(
                membership.membershipType,
                membership.groomingMembershipType
            );
        }
    }, [initialMembership, membership.membershipType, membership.groomingMembershipType]);

    const calculatePriceDifference = (newMembershipType, newGroomingType) => {
        const currentMembershipPrice = membershipPrices[initialMembership.membershipType] || 0;
        const newMembershipPrice = membershipPrices[newMembershipType] || 0;
        const currentGroomingPrice = groomingPrices[initialMembership.groomingMembershipType] || 0;
        const newGroomingPrice = groomingPrices[newGroomingType] || 0;
        const difference = (newMembershipPrice + newGroomingPrice) - (currentMembershipPrice + currentGroomingPrice);
        setPaymentDifference(difference);
        setIsUpgrade(difference > 0);
    };

    const handleUpdate = async () => {
        try {
            await updateMembership(membershipId, {
                membershipType: membership.membershipType,
                groomingMembershipType: membership.groomingMembershipType,
                stripeCustomerId,
                stripePaymentMethodId: defaultMethod.stripePaymentMethodId,
                paymentDifference
            });
            await refreshUserData(user.uid);
            navigate('/profile');
        } catch (error) {
            console.error('Failed to update membership:', error);
        }
    };

    const handleMembershipCancel = async () => {
        setShowDeletePopup(true);
        window.scrollTo(0, 0);
    };

    const handlePopupDelete = async () => {
        try {
            await deleteMembership(membershipId);
            await refreshUserData(user.uid);
            navigate('/profile');
        } catch (error) {
            console.error('Failed to delete membership:', error);
        } finally {
            setShowDeletePopup(false);
        }
    };

    const handlePopupCancel = () => {
        setShowDeletePopup(false);
    };

    const handlePopupUpdateCancel = () => {
        setShowUpdatePopup(false);
    };

    const handlePopupUpdate = () => {
        setShowUpdatePopup(false);
        handleUpdate();
    };

    const handleHelpClicked = () => {
        navigate('/support');
        window.scroll(0,0);
    }

    const placeholderImages = {
        Human: personPlaceholder,
        Dog: dogPlaceholder,
        Cat: catPlaceholder,
    };

    function getPlaceholderImage(type) {
        return placeholderImages[type] || placeholderImages.Human;
    }

    const renderBenefitsList = (benefits) => {
        if (!benefits) return null;
        return (
            <ul>
                {benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                ))}
            </ul>
        );
    };

    const isMembershipChanged = () => {
        return (
            initialMembership.membershipType !== membership.membershipType ||
            initialMembership.groomingMembershipType !== membership.groomingMembershipType
        );
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    const getTruncatedMembershipType = (type) => {
        if (!type || !type.includes(" - ")) return type; // Return as-is if no " - "
        return type.split(" - ")[1].trim(); // Extract the part after " - " and trim spaces
    };      

    return (
        <div className='container'>
            <BackButton />
            <div className='membership-edit-backdrop'>
                <Popup isOpen={showDeletePopup} handleClose={handlePopupCancel}>
                    <p className='membership-edit-popup-header'>Cancel Membership?</p>
                    <p className='membership-edit-popup-message'>
                        We're sad to see you go! Are you sure you want to cancel your membership?
                    </p>
                    <p className='membership-edit-popup-message'>
                        <strong>Effective date: {membership.endDate}</strong>
                    </p>
                    <div className='membership-edit-popup-button-container'>
                        <button onClick={handlePopupDelete} className='membership-edit-popup-delete'>Confirm</button>
                        <button onClick={handlePopupCancel} className='membership-edit-popup-cancel'>Cancel</button>
                    </div>
                </Popup>
                <Popup isOpen={showUpdatePopup} handleClose={() => setShowUpdatePopup(false)}>
                    <p className='membership-edit-popup-header'>Update Membership?</p>
                    <p className='membership-edit-popup-message'>
                        It will result in a payment adjustment based on the price differences, and monthly recurring payments.
                    </p>
                    {isUpgrade
                        ? <p className='membership-edit-popup-message'>Your payment difference is <strong>${paymentDifference}</strong>. Do you wish to update the membership immediately?</p>
                        : <p className='membership-edit-popup-message'>Your membership will be downgraded in the next billing cycle.</p>
                    }
                    <p className='membership-edit-popup-message'>
                        <strong>Effective date: {membership.endDate}</strong>
                    </p>
                    <div className='membership-edit-popup-button-container'>
                        <button onClick={() => handlePopupUpdate(true)} className='membership-edit-popup-delete'>Update Now</button>
                        <button onClick={handlePopupUpdateCancel} className='membership-edit-popup-cancel'>Cancel</button>
                    </div>
                </Popup>
                <div className='membership-edit-member-info'>
                    <img 
                        className='membership-edit-image' 
                        src={member.image || getPlaceholderImage(member.type)} 
                        alt={`${member.type} Placeholder`} 
                    />
                    <div className='membership-edit-info-details'>
                        <h3 className='membership-edit-name'>{member.name}</h3>
                        <p className='membership-edit-type'>{member.type}</p>
                    </div>
                </div>
                <div className="membership-edit-form">
                    <form onSubmit={(e) => { e.preventDefault(); setShowUpdatePopup(true); }}>
                        <div className="membership-edit-section">
                            <h2 className='membership-edit-header'>Membership Package</h2>
                            <p><strong>Since:</strong> {new Date(membership.startDate).toLocaleDateString()}</p>
                            <label>
                                <strong>Type:</strong>
                                <select
                                    value={getTruncatedMembershipType(membership.membershipType) || ''}
                                    onChange={(e) => setMembership({ ...membership, membershipType: e.target.value })}
                                >
                                    <option value="Basic">Basic</option>
                                    <option value="Premium">Premium</option>
                                    <option value="VIP">VIP</option>
                                </select>
                            </label>
                            <div className='membership-edit-benefits'>
                                <strong>Benefits:</strong>
                                {renderBenefitsList(membershipBenefits[getTruncatedMembershipType(membership.membershipType)])}
                            </div>
                        </div>
                        <div className="membership-edit-section">
                            <h2 className='membership-edit-header'>Grooming Package</h2>
                            <label>
                                <strong>Type:</strong>
                                <select
                                    value={membership.groomingMembershipType || ''}
                                    onChange={(e) => setMembership({ ...membership, groomingMembershipType: e.target.value })}
                                >
                                    <option value="Basic">Basic</option>
                                    <option value="Premium">Premium</option>
                                    <option value="VIP">VIP</option>
                                </select>
                            </label>
                            <div className='membership-edit-benefits'>
                                <strong>Benefits:</strong>
                                {renderBenefitsList(groomingBenefits[membership.groomingMembershipType])}
                            </div>
                        </div>
                        <div className="membership-edit-section">
                            <h2 className='membership-edit-header'>Monthly Remaining</h2>
                            <p>
                                <strong>Daycare:</strong> {membership.daycareRemaining}
                            </p>
                            <p>
                                <strong>Grooming:</strong> {membership.groomingMembershipType === 'VIP' ? '∞' : membership.groomingRemaining}
                            </p>
                            <p>
                                <strong>Bath:</strong> {membership.groomingMembershipType === 'VIP' ? '∞' : membership.bathRemaining}
                            </p>
                        </div>
                        <div className='membership-edit-buttons-container'>
                            <button type="submit" className='membership-edit-update-button' disabled={!isMembershipChanged()}>Update</button>
                            <button type="button" onClick={handleHelpClicked} className='membership-edit-contact-support-button'>Help</button>
                            <button type="button" onClick={handleMembershipCancel} className='membership-edit-cancel-button'>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MembershipEdit;
