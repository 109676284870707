import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StateContext } from '../StateProvider';
import './Navigation.css';

import logo from '../../assets/Navigation/logo_long.png';
import logoSmall from '../../assets/Navigation/logoSmall.png';
import menuButton from '../../assets/Navigation/menuButton.png';
import closeButton from '../../assets/Navigation/closeButton.png';

const Navigation = () => {
  const {
    role,
    loggedIn,
    setLoading,
  } = useContext(StateContext);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const loginClicked = () => {
    navigate(`/login`); 
    window.scrollTo(0, 0);
  }

  const adminClicked = () => {
    navigate(`/admin`); 
    window.scrollTo(0, 0);
  }

  return (
    <nav className="navbar">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img className="navLogo" src={logo} alt="Logo" />
          <img className="navLogoSmall" src={logoSmall} alt="Small Logo" />
        </Link>
        <div className="navbar-buttons">
          {/* Login Button */}
          {!loggedIn && (
            <button className="navbar-login-button" onClick={loginClicked}>
              LOGIN
            </button>
          )}
          {(loggedIn && (role === 'admin' || role === 'staff')) && (
            <button className="navbar-login-button" onClick={adminClicked}>
              Staff
            </button>
          )}

          {/* Dropdown Toggle Button */}
          <button className={`navbar-toggle ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
            <img className="dropdown-icon" src={menuButton} alt="Dropdown Icon" />
          </button>
        </div>
      </div>
      
      {isDropdownOpen && (
        <ul className="dropdown-menu">
        <div className="dropdown-top">
          <div className="dropdown-left">
            <Link to="/" className="navbar-brand" onClick={closeDropdown}>
              <img className="navLogoSmall" src={logoSmall} alt="Small Logo" />
            </Link>
          </div>
          <div className="dropdown-right">
            <button className="close-button" onClick={closeDropdown}>
              <img src={closeButton} alt="Close Button" className='close-button-image' />
            </button>
          </div>
        </div>
        <div className="dropdown-items">
        <div className="dropdown-category">
            <h3 className='dropdown-title'>Services</h3>
            <li className="dropdown-item">
              <Link to="/store/services/hotel" className="dropdown-link" onClick={closeDropdown}>
                Hotel
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/store/services/shuttle" className="dropdown-link" onClick={closeDropdown}>
                Shuttle
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/store/services/daycare" className="dropdown-link" onClick={closeDropdown}>
                Daycare
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/store/services/training" className="dropdown-link" onClick={closeDropdown}>
                Training
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/store/services/grooming" className="dropdown-link" onClick={closeDropdown}>
                Grooming
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/store/services/adventure" className="dropdown-link" onClick={closeDropdown}>
                Adventures
              </Link>
            </li>
            {/* <li className="dropdown-item">
              <Link to="/store/services/private-events" className="dropdown-link" onClick={closeDropdown}>
                Private Events
              </Link>
            </li> */}
          </div>
          <div className="dropdown-category">
            <h3 className='dropdown-title'>Products</h3>
            <li className="dropdown-item">
              <Link to="/coming-soon" className="dropdown-link" onClick={closeDropdown}>
                Food
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/coming-soon" className="dropdown-link" onClick={closeDropdown}>
                Homes
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/coming-soon" className="dropdown-link" onClick={closeDropdown}>
                Apparels
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/coming-soon" className="dropdown-link" onClick={closeDropdown}>
              Health & Wellness
              </Link>
            </li>
          </div>
          <div className="dropdown-category">
            <h3 className='dropdown-title'>Company</h3>
            <li className="dropdown-item">
              <Link to="/about" className="dropdown-link" onClick={closeDropdown}>
                About
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/career" className="dropdown-link" onClick={closeDropdown}>
                Career
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/culture" className="dropdown-link" onClick={closeDropdown}>
                Culture
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/locations" className="dropdown-link" onClick={closeDropdown}>
                Locations
              </Link>
            </li>
            <li className="dropdown-item">
              <Link to="/franchise" className="dropdown-link" onClick={closeDropdown}>
                Franchise
              </Link>
            </li>
          </div>
        </div>
      </ul>
      )}
    </nav>
  );
};

export default Navigation;
